import config from 'react-global-configuration'
config.set({
    ga_tag: "",
    ga_debug: false,
    base:"https://xgamesv1.bngrenew.com/api",
    elastic_base_url:"https://serviceusagesv1.bngrenew.com/publish",
    domain : "btc",
    he : "/he",
    subscribe: '/subscribe',
    requestOTP: "/requestOTP",
    activate: "/activate",
    checkSub: "/checkSub",
    unsubscribe : '/unsubscribe',
    userPacks : '/userPacks',
    appConfig : '/config' , 
    subscribe:"/subscribe",
    sendOtp:"/pinsend",
    pinSubmit:"/pinsubmit",
    passToken:"/passToken",
    verifyPin:"/verifyPIN",
    headers: {
        'Reqfrom': 'web',
        'Username': 'web',
        'Servicefor':'ais_Th'
    }
})


export default config