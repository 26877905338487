import React from "react"
import './Subscribe.scss'
import {Link} from "react-router-dom"
import notFound from "../assets/icons/404error_icon.png"
function NotFound({logo}) {
    console.log('inside NotFound container')
    return (
        <div className="container m-w">
            <div className="columns is-mobile">
                <div className="column is-full">
                    <div className="card">
                        <div className="card-content">
                            {/* <figure className="image z-s m-1">
                                <img src={zain} alt="zain logo"/>
                            </figure> */}
                            <figure className="image l-s m-2">
                                <img src={logo} alt="game logo"/>
                            </figure>
                            <figure className="image s-s m-5 pb-5">
                                <img src={notFound} alt="subcribe icon"/>
                            </figure>
                            <div className="column has-text-centered">
                                <p>The requested page is not found.</p>
                                <Link to="/" className="button is-rounded btn-main mt-3">Go To Home </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound