
import React from "react";
import Profile from "./Profile";
import './SideMenu.scss';
import closeIcon from '../assets/icons/menu_close_icon.svg'

function SideMenu({ sideMenu, handleClose, menuLogo, menuColor }) {
  
  return (
    <>
      {sideMenu && (
        <>
          <div className="sideMenu" style={{backgroundColor : menuColor}}>
          <div className="px-4 pb-2 mt-4" onClick={handleClose} style={{ float: 'left'}}>
              {menuLogo && <img src={menuLogo ?menuLogo :null} alt="close" width="100"/>}
              </div>
            <div className="px-4 pb-2 mt-4" onClick={handleClose} style={{ float: 'right' }}>
              <img src={closeIcon} alt="close" />
            </div>
            <Profile handleClose={handleClose}  menuColor={menuColor}/>

          </div>
        </>
      )}
    </>
  );
}

export default SideMenu