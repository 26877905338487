import React,{useState,useEffect} from "react"
import Slider from "react-slick"
import "./Slider.scss"
import arrow from "../assets/icons/forward.png"
import { Link } from "react-router-dom"
import { logEvent } from '../Analytics/AnalyticsEvent';
import { screen, events } from '../Analytics/EventName';
import { reactLocalStorage } from "reactjs-localstorage"
import starLink from "./../assets/icons/complete.png";
import './GamesCatgorySlider.scss';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useLanguage } from "../Context/LangContext"

function GameCategorySlider(props) {
    console.log('inside GamesSlider Component', props);
    const location=useLocation();
    const { settings, data,icon} = props
    const categories = Object?.keys(data);
    const {selectedCategory,setSelectedCategory}=useLanguage()
      console.log("selectedCategory is:::",selectedCategory)
    const slideritems = categories?.map((category, i) => (
        <Link to={{pathname:"/category",state: { categoryData: data[category] }}} key={i}  >
            <div className={`slider-card top-slider-card ${selectedCategory?.toLowerCase()===data[category]?.title?.toLowerCase()?'top-slider-card-selected':null}`} onClick={() => {   
                setSelectedCategory(data[category]?.title)
                
                    reactLocalStorage.set("category-clicked",data[category].title)
                
            logEvent(
                {
                    screen: screen.homePage,
                    event : data[category].title + "_cardClick"
                },
                {
                    gameName : category,
                    mobile : reactLocalStorage.get('msisdn'),
                    date: new Date(),
                }); 
                console.log("data[category]:::",data[category]?.title)               
           }}>
            <img src={data[category]?.imglink} alt={data[category].title}/>
            <span className="capitalize">{data[category]?.title}</span>
              </div>
        </Link>
    ))
 
    return (
        <div className="columns is-multiline is-mobile common is-gapless">       
            <div className="column is-full" >
                <Slider {...settings}>
                    {slideritems}
                </Slider>
            </div>  
        </div>
    )
}

export default GameCategorySlider