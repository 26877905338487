import React,{useState,useEffect} from "react"
import Slider from "react-slick"
import "./Slider.scss"
import arrow from "../assets/icons/forward.png"
import { Link } from "react-router-dom"
import { logEvent } from '../Analytics/AnalyticsEvent';
import { screen, events } from '../Analytics/EventName';
import { reactLocalStorage } from "reactjs-localstorage"
import starLink from "./../assets/icons/complete.png"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useLanguage } from "../Context/LangContext"

function BannerSlider(props) {
    console.log('inside GamesSlider Component', props);
    const {setSelectedCategory}=useLanguage();
    const location=useLocation();
    const { settings, data, title,view_btn_txt,icon} = props;
    const dataToPass={
        title:title,
        contents:data
    }
    const slideritems = data?.map((item, i) => (
      
        <Link to={`/game/${item.category}/${item.id}`} key={i}  >
            <div className="slider-card" onClick={() => {   
                if(location.pathname==="/" || location.pathname==="/home" ) 
                {
                    reactLocalStorage.set("category-clicked",title)
                }
            logEvent(
                {
                    screen: screen.homePage,
                    event : item.gameName + "_cardClick"
                },
                {
                    gameName : item.gameName,
                    mobile : reactLocalStorage.get('msisdn'),
                    date: new Date(),
                });
                
        }}>
            
                <div className="card-image">
                    <figure className="image is-1by1">
                        <img src={item.gameImage} alt={item.title} className="img-radius lazy-img" />
                    </figure>
                </div>
                          <div className="flex p-2">
                          <div className="flex-left">
                         <h6 className="mb-0 text-white">{item.gameName}</h6>
                         </div>
                        {/* <div className="flex-right">
                        <figure className="display">
                        {item.ratings}
                         <img src={starLink} className="star-icon rating-icon m-auto"/>                 
                       </figure>     
                        </div>          */}
                      </div>
                </div>
        </Link>
    ))
    return (
        <div className="columns is-multiline is-mobile common is-gapless">
            <div className="column is-full v-center display">
                <div>
                <span className="title is-6 mb-0  text-white line capitalize">{title}</span>
                <img className="cat-icon" src={icon}/>
                </div>
                <div onClick={()=>
                {
                    if(location.pathname==="/home" ) 
                {
                    reactLocalStorage.set("category-clicked",title)
                    setSelectedCategory(title)
                }
                }}>
                 <Link className="is-small ml-auto text-white p-1 view-btn" to={{pathname:"/category",state:{categoryData:dataToPass}}} >
                                {view_btn_txt} 
                    <img src={arrow} alt="arrow" className="ml-1" onClick={()=>setSelectedCategory(title)} />
                </Link> 
                </div>             
            </div>
            <div className="column is-full" >
                <Slider {...settings}>
                    {slideritems}
                </Slider>
            </div>
        </div>
    )
}

export default BannerSlider