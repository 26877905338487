import React from "react"
import Slider from "react-slick"

function BannerSlider(props) {
    // console.log('inside BannerSlider Component', props)
    const {settings, data} = props
    console.log(">>>data:::", data)
    const slideritems = data.map((item, i) => (
        <div key={i}>
            <figure className="image is-2by1" key={item.id}>
                <img
                    src={item.img}
                    alt={item.title}
                    className="lazy-img"
                    />
            </figure>
        </div>
    ))
    return (
        <div className="columns is-mobile is-gapless">
            <div className="column is-full">
                <Slider {...settings}>
                    {slideritems}
                </Slider>
            </div>
        </div>
    )
}

export default BannerSlider