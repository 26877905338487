import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import Facebook from '../assets/icons/social/facebook.png'
import Instagram from '../assets/icons/social/instagram.png'
import Linkedin from '../assets/icons/social/linkedin.png'


function Footer() {
    const {pathname} = useLocation()
    console.log('>>>pathname:::', pathname)
    return (
        <div className="columns is-gapless pt-3 pb-3">
            <div className="column is-full is-mobile has-text-centered">
                {/* {
                    pathname === '/' &&  <Link to="/freegames" className="button is-primary is-small is-inverted is-outlined">Play Free Game</Link>
                } */}
                {/* <nav className="navbar is-mobile is-transparent" role="navigation" aria-label="main navigation">
                    <div className="navbar-menu">
                        <a href="https://www.facebook.com/ZainSudan/" className="navbar-item" rel="noreferrer" target="_blank"><img src={Facebook} alt="social logo"/></a>
                        <a href="https://www.instagram.com/zainsudan/" className="navbar-item" rel="noreferrer" target="_blank"><img src={Instagram} alt="social logo"/></a>
                        <a href="https://www.linkedin.com/company/zain-sudan/" className="navbar-item" rel="noreferrer" target="_blank" ><img src={Linkedin} alt="social logo"/></a>
                    </div>
                </nav>
                <a href="https://www.linkedin.com/company/zain-sudan/" className="has-text-white" rel="noreferrer" target="_blank">Powered by
                    <img src={Zain} alt="Zain logo" width="40"/></a> */}
            </div>
        </div>
    )
}

export default Footer