import React,{useEffect, useState} from "react"
import success from "../assets/icons/tick_icon.png"
import error from "../assets/icons/error_icon.png"
import lowBal from "../assets/icons/low_balance_icon1.png"
import './SubscriptionModal.scss'
import { SubscriptionModalText as txt } from "../Text"
import { useLanguage } from "../Context/LangContext"
import { sendWapRedirection } from "../CommonSript"
import { reactLocalStorage } from "reactjs-localstorage"
import HomeLoader from "./HomeLoader"
function SubscriptionModal() {
    const {successModal,lowBalModal,errorModal}=txt;
    const {language}=useLanguage();
    const [loading,setLoading]=useState(false);
    const status=reactLocalStorage.get("modalType") || "failed";
    const msgtext=reactLocalStorage.get("msg") 
    const btntext=reactLocalStorage.get("btnText");
    const subUrl=reactLocalStorage.get("subUrl")
    function handleErrorClick()
    {
        setLoading(true);
        sendWapRedirection('checksub_modal','sub_redirection',subUrl);
    }
    if(loading) return <HomeLoader/>;
    return (
        <div className="container m-w subContainer" style={{whiteSpace:"break-spaces",wordBreak:"break-word"}}>
            <div className="columns is-mobile">
                <div className="column is-full">
                {status==="success" &&
                <div>
                    <img src={success} alt="success" className="status-img"/>
                    <h1>{successModal?.title[language]}</h1>
                    <p>{successModal?.mainTitle[language]}</p>
                    <h2>{successModal?.subTitle[language]}</h2>
                </div>
                }
                {status==="failed" &&
                <div className="text-center">
                    <img src={error} alt="success" className="status-img"/>
                    <h1>{errorModal?.title[language]}</h1>
                    <h2>{msgtext}</h2>
                    <p>{errorModal?.subTitle[language]}</p>
                    <button className="btn bold" onClick={handleErrorClick}>
                        {btntext}
                    </button>
                </div>
                }
                  {(status==="pending" || status==="grace" || status==="lowBalance") &&
                <div>
                    <img src={lowBal} alt="success" className="status-img"/>
                    <h1>{lowBalModal?.title[language]}</h1>
                    <h2>{lowBalModal?.mainTitle[language]}</h2>
                    <button className="btn bold">{lowBalModal?.btnText[language]}</button>
                </div>
                } 
                </div>
            </div>
        </div>
    )
}

export default SubscriptionModal