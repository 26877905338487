import React, { useEffect, useState } from "react";
import './Subscription.scss'
import BannerSlider from "../commonComponents/BannerSlider";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import config from "../config";
import { useLanguage } from "../Context/LangContext";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { reactLocalStorage } from "reactjs-localstorage";
import { SignInPageText as text } from "../Text";
import { SendGuiDataEvents } from "../CommonSript";
import SubscriptionLoader from "./SubscriptionLoader";
import SubModal from "./SubModal";
import { logEventHome } from "../Analytics/AnalyticsEvent";
import { screen } from "../Analytics/EventName";
const FillOtp = (props) => {
  const banners = props.banners;
  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [msisdn, setMsisdn] = useState('');

  useEffect(() => {
    let guiEvents = {};
    guiEvents["page"] = "login";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
  }, []);

  const handleMsisdnChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setMsisdn(value);
    }
  };
  const isFormValid = msisdn?.length >= 8 && msisdn?.length <= 11;
  const history = useHistory();

  const matchOtp = () => {
    if (isFormValid) {
      checksub(msisdn)
    }
  }
  const checksub = async (num) => {
    if (num) {
      let guiEvents = {};
      guiEvents["page"] = "login";
      guiEvents["event"] = "checksub_request";
      SendGuiDataEvents(guiEvents);
      setLoading(true)
      const url = `${config.get('base')}${config.get('checkSub')}`
      const headers = {
        ...config.get('headers'),
        Language: language
      }
      try {
        const res = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ msisdn: num }),
          headers,
        });

        if (!res.ok) throw res;

        const result = await res.json();
        reactLocalStorage.set('subStatus', result?.subStatus);
        reactLocalStorage.set('status_display', result?.status_display);
        reactLocalStorage.set('showUnsub', result?.showUnsub);
        reactLocalStorage.set('msisdn', result?.msisdn);
        reactLocalStorage.set('uid', result?.uid);
        reactLocalStorage.set('unsubtext', result?.unsub_msg_txt);
        let guiEvents1 = {};
        guiEvents1["page"] = "login";
        guiEvents1["event"] = "checksub_response";
        guiEvents1["status"] = result?.subStatus;
        SendGuiDataEvents(guiEvents1);
        if (result?.status === 'success') {
          if (result?.subStatus === "new") {
            reactLocalStorage.set("initStatus", 'new')
          }
          else if (result?.subStatus === 'active') {
            history.push("/home")
          }
          else if (result?.subStatus?.toLowerCase() === 'inactive') {
            setLoading(false)
            reactLocalStorage.set("msg", result?.msg1)
            reactLocalStorage.set("btnText", result?.btn_txt)
            history.push("/modal")
          } else {
            history.push('/login');
          }
        }
        else {
          setLoading(false)
          reactLocalStorage.set("msg", result?.msg1)
          reactLocalStorage.set("btnText", result?.btn_txt)
          history.push("/modal")
        }
        setLoading(false)
        logEventHome(
          {
            screen: screen.otpPage,
            event: 'checksub_api_success',
          },
          {
            status: result.subStatus || '',
            msisdn: result.msisdn || 'NA',
          }
        );
      } catch (error) {
        console.log('Error during subscription check:', error);
      }
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500
  };
  if (loading) return <SubscriptionLoader />;
  return (
    <div className="container" style={{ whiteSpace: "break-spaces" }}>
      {banners && banners.length ? (
        <BannerSlider
          settings={{
            ...settings,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            infinite: true,
            swipe: true,
            autoplay: true,
            speed: 200,
            autoplaySpeed: 1500,
            cssEase: "linear",
            customPaging: () => <div className="dot-outer"><div className="dot"></div></div>,
          }}
          data={banners} />) : ""}

      <p className="text">
        {text.title[language]}
      </p>

      <form className='form-container'>
        <div className="inputBox pb-6">
          <label htmlFor="msisdn">  {text?.msisdnText[language]}</label>
          <div className="msisdn-field">
            <p style={{ padding: '10px', backgroundColor: '#eee', border: '1px solid #ccc', borderRight: 'none' }}>
              +66
            </p>
            <input
              type="text"
              id="msisdn"
              placeholder={text.msisdnPlaceholder[language]}
              name="msisdn"
              value={msisdn}
              onChange={handleMsisdnChange}
              autoComplete="off"
            />
          </div>
        </div>

        <button className="btn bold" onClick={matchOtp} disabled={!isFormValid}>
          {text.logInBtnText[language]}
        </button>
      </form>
      <p className="singUp">{text.singUpText[language]} <Link to="/signup">{text?.signUpBtnText[language]}</Link>{" "}{text?.hereText[language]}</p>
    </div>
  )
}

export default FillOtp